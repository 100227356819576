import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import debounce from '../../../utils/debounce';
import ButtonCloseModal from '../../../components/ButtonCloseModal';

const initialNovoDetalhe = {
  idChamado: null,
  idDetalhe: null,
  descricao: null,
  previsaoEntrega: new Date().toJSON(),
  previsaoInicio: new Date().toJSON(),
  responsavelAprovacao: null,
  responsavelExecucao: null,
  observacao: '',
};

const initialNovoDetalheFilter = [
  {
    idChamado: null,
    idDetalhe: null,
    descricao: null,
    previsaoEntrega: new Date().toJSON(),
    previsaoInicio: new Date().toJSON(),
    responsavelAprovacao: null,
    responsavelDemandado: null,
    observacao: '',
  },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '55%',
  height: '55%',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ModalDetalhes({
  open,
  onClose,
  idChamadoSelecionado,
  handleSubmitDet,
  detalheSelecionado,
  handleUpdateDet,
  handleEnviarEmail,
  detalheId,
  idChamadoSel,
  listaMembros,
}) {
  const [formDataDet, setFormDataDet] = useState(initialNovoDetalhe);
  const [formDataExistente, setFormDataExistente] = useState(
    initialNovoDetalheFilter
  );

  const setDataDetalhes = () => {
    setFormDataExistente(detalheSelecionado.chamadosDetalhes);
  };

  const filtroDetalhes = () => {
    const detalheFiltrado = initialNovoDetalheFilter;
    detalheFiltrado.length = 0;
    for (const item of formDataExistente) {
      if (item.idDetalhe == detalheId) {
        detalheFiltrado.push(item);
      }
    }
    if (detalheFiltrado.length != 0) {
      setFormDataDet(() => ({
        idDetalhe: detalheFiltrado[0].idDetalhe,
        descricao: detalheFiltrado[0].descricao,
        previsaoEntrega: detalheFiltrado[0].previsaoEntrega,
        previsaoInicio: detalheFiltrado[0].previsaoInicio,
        responsavelAprovacao: detalheFiltrado[0].responsavelAprovacao,
        responsavelExecucao: detalheFiltrado[0].responsavelDemandado,
        observacao: detalheFiltrado[0].observacao,
      }));
    }
  };

  useEffect(() => {
    if (detalheSelecionado) {
      setDataDetalhes();
      filtroDetalhes();
    }
    setFormDataDet((oldState) => ({
      ...oldState,
      idChamado: idChamadoSelecionado,
    }));
  }, [detalheSelecionado, idChamadoSelecionado]);

  const handleChange = (e) => {

    const { name, value } = e.target;
    setFormDataDet((prevData) => ({
      ...prevData,
      [name]: value,
      idChamado: detalheSelecionado ? idChamadoSel : idChamadoSelecionado,
      // idChamado: idChamadoSelecionado
    }));
  };

  const handleSubmitFormData = (e) => {
    e.preventDefault();
    handleSubmitDet(formDataDet);
    handleEnviarEmail(formDataDet);
    setFormDataDet(initialNovoDetalhe);
    onClose();
  };

  const handleSubmitUpdateFormData = () => {
    handleUpdateDet(formDataDet);
    handleEnviarEmail(formDataDet);
    setFormDataDet(initialNovoDetalhe);
    onClose();
  };

  const cancelFormData = () => {
    setFormDataDet(initialNovoDetalhe);
    onClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <ButtonCloseModal onClick={cancelFormData} />
          {detalheSelecionado ? (
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', color: '#000' }}
            >
              Atualizar Detalhe
            </Typography>
          ) : (
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', color: '#000' }}
            >
              Adicionar Detalhe
            </Typography>
          )}
          <form onSubmit={handleSubmitFormData}>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, my: 2 }}>
              <TextField
                id="filled-basic"
                label="Email do Demandante"
                variant="filled"
                fullWidth
                name="responsavelAprovacao"
                onChange={handleChange}
                required
                value={formDataDet.responsavelAprovacao}
              />
              <TextField
                id="filled-basic"
                label="Email Do Responsável pela Execução"
                variant="filled"
                fullWidth
                name="responsavelExecucao"
                onChange={handleChange}
                required
                value={formDataDet.responsavelExecucao}
              />
            </Box>
            <FormControl fullWidth>
              <TextField
                variant="filled"
                id="outlined-multiline-static"
                label="Descrição"
                name="descricao"
                placeholder="Descreva com todos os detalhes possíveis e claros"
                multiline
                rows={3}
                required
                value={formDataDet.descricao}
                onChange={handleChange}
              />
            </FormControl>
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                marginBottom: 2,
                color: formDataDet.descricao?.length < 20 ? '#f00' : 'transparent',
              }}
            >
              {formDataDet.descricao?.length < 20
                ? `Faltam ${20 - formDataDet.descricao.length} caracteres para o mínimo.`
                : ''}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                marginBottom: 2,
                color: '#f00',
                visibility:
                  !formDataDet.responsavelAprovacao ||
                    !formDataDet.responsavelExecucao ||
                    !formDataDet.descricao
                    ? 'visible'
                    : 'hidden',
              }}
            >
              Todos os campos acima são obrigatórios
            </Typography>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <TextField
                variant="filled"
                id="outlined-multiline-static"
                label="Observação"
                name="observacao"
                placeholder="Descreva com todos os detalhes possíveis e claros"
                multiline
                inputProps={{ maxLength: 99 }}
                rows={2}
                value={formDataDet.observacao}
                onChange={handleChange}
              />
            </FormControl>

            <Box sx={{ display: 'flex', gap: 2, paddingY: 2 }} fullWidth>
              {detalheSelecionado ? (
                <Button
                  variant="contained"
                  color="success"
                  endIcon={<AddIcon />}
                  fullWidth
                  type="submit"
                  onClick={handleSubmitUpdateFormData}
                >
                  Atualizar
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  endIcon={<AddIcon />}
                  fullWidth
                  type="submit"
                  disabled={
                    !formDataDet.responsavelAprovacao ||
                    !formDataDet.responsavelExecucao ||
                    !formDataDet.descricao ||
                    formDataDet.descricao.length < 20
                  }
                  onClick={(e) => {
                    handleSubmitFormData(e);
                  }}
                >
                  Adicionar
                </Button>
              )}
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                fullWidth
                onClick={cancelFormData}
                type="reset"
              >
                Cancelar
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
}
