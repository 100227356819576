import { Box, FormLabel, IconButton, TextField } from '@mui/material';
import { useNavigate } from 'react-router';
import useUsuarioLocal from '../../hooks/usuarioLocal.hook';
import './styles.css';

import SidebarNovo from '../../components/LayoutNovo/SidebarNovo';

import Amvoxlogopng from '../../assets/Amvoxlogopng.png';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { useToast } from '../../hooks/components/toast.hook';

import { buscaApontamentos } from '../../services/apontamentosTi.service';

import TableApontamentos from './ApontamentosTable';
import ModalCadastroInicial from './ModalCadastrarApontamento/index';
import { useEffect, useState } from 'react';

export default function ApontamentosWeb() {
  const [data, setData] = useState([]);
  const [totalMin, setTotalMin] = useState([]);

  const { nome, id } = useUsuarioLocal();

  const { addToast } = useToast();

  const navigate = useNavigate();

  const handleFetchApontamentos = () => {
    buscaApontamentos(id)
      .then((response) => {
        setData(response.apontamento);
        setTotalMin(response.totalMinutos);
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro',
          description: 'Erro ao trazer tabela!',
        });
      });
  };

  useEffect(() => {
    handleFetchApontamentos();
  }, []);

  return (
    <div className="principal">
      <div className="sidebar">
        <SidebarNovo />
      </div>
      <Box position={'relative'} sx={{ backgroundColor: '#F2F2F2' }} gap={2}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 4,
          }}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
          <img src={Amvoxlogopng} alt="Amvox" className="imgAmvox" />
          <Box
            className="poppins-bold"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pr: 2,
              mb: 2,
              ml: '20px',
            }}
          >
            Apontamentos na Web
          </Box>
        </Box>

        <div className="divGeral">
          <div className="divConsultar">
            <Box className="poppins-regular" sx={{ ml: 2, mt: 2 }}>
              Options
            </Box>
            <div className="divSelects">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '32%',
                  paddingLeft: '15px',
                }}
              >
                <FormLabel sx={{ textAlign: 'center', color: 'black' }}>
                  Colaborador
                </FormLabel>
                <TextField
                  sx={{ backgroundColor: '#fff' }}
                  size="small"
                  variant="filled"
                  color="success"
                  defaultValue={nome}
                  disabled
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '30%',
                  paddingLeft: '25px',
                  justifyContent: 'center',
                }}
              >
                <FormLabel sx={{ textAlign: 'center', color: 'black' }}>
                  Registrar
                </FormLabel>
                <ModalCadastroInicial
                  nome={nome}
                  handleFetchApontamentos={handleFetchApontamentos}
                />
              </div>
            </div>
          </div>
          <Box className="card">
            <div className="card-content">
              <h2>Total Minutos</h2>
              <Box className="texto-rgb">{totalMin}</Box>
            </div>
          </Box>
        </div>

        <Box
          margin={1}
          paddingTop={3}
          justifyContent={'center'}
          alignItems={'center'}
          gap={2}
          pr={4}
          pl={4}
        >
          <TableApontamentos
            nome={nome}
            isUsuario={id}
            data={data}
            handleFetchApontamentos={handleFetchApontamentos}
          />
        </Box>
      </Box>
      <footer className="footerPage">Amvox 2024</footer>
    </div>
  );
}
