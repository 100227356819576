import { apiFabrica } from "../apis";

export const GetApontamentoChamados = async (filtro) => {
    try {
        const result = await apiFabrica.get(`ChamadosXControllerIndicadores?EmailUsuario=${filtro}`);
        return Promise.resolve(result.data);
    } catch (error) {
        return Promise.reject(error);
    }
}
