import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import LayoutNovo from '../../components/LayoutNovo';
import { ContainerContact } from './styles';
import { useToast } from '../../hooks/components/toast.hook';
import { EnviarFormEmail } from '../../services/email.service';
import useUsuarioLocal from '../../hooks/usuarioLocal.hook';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import XTabelaChamadosUser2 from './tablex/Xindex';
import {
  GetChamadosXSolicitantes,
  postChamadosX,
} from '../../services/chamados/chamadosX.service';
import { BsFillMegaphoneFill } from 'react-icons/bs';
import AddIcon from '@mui/icons-material/Add';
import ModalDetalhesX from './modalDetalhes/Xindex';
import { ChamadosXFecharPut } from '../../services/fecharChamado';
import { consultaSetores } from '../../services/setores/setores.service';
import { apiFabrica } from '../../services/apis';
import XModalAbrirChamado from './modal/Xindex';
import { ChamadosTiposGet } from '../../services/chamados.service';
import { ChamadosStatusGet } from '../../services/chamados.service';
import { ChamadoSituacaoGet } from '../../services/chamados.service';
import { ChamadoCategoriaGet } from '../../services/chamados.service';

const interfaceListaDeTipo = {
  id: '',
  nome: '',
  observacao: '',
};

const interfaceListaDeStatus = {
  id: '',
  nome: '',
  observacao: '',
};

const interfaceListaDeSituacao = {
  id: '',
  nome: '',
  observacao: '',
};

const interfaceListaDeCategoria = {
  id: '',
  nome: '',
  observacao: '',
};

const XContato = () => {
  const { email, nome, setor } = useUsuarioLocal();
  const [listaSetores, setListaSetores] = useState([]);
  const [idChamadoSelecionado, setIdChamadoSelecionado] = useState(null);
  const [listaChamadosDetalhes, setListaChamadosDetalhes] = useState([]);
  const [filtro, setFiltro] = useState({ solicitante: email });
  const [chamadoDescricao, setChamadoDescricao] = useState();
  const [chamadoTitulo, setChamadoTitulo] = useState();
  const [listaDeTipo, setListaDeTipo] = useState([interfaceListaDeTipo]);
  const [listaDeStatus, setListaDeStatus] = useState([interfaceListaDeStatus]);
  const [listaDeSituacao, setListaDeSituacao] = useState([
    interfaceListaDeSituacao,
  ]);
  const [listaDeCategoria, setListaDeCategoria] = useState([
    interfaceListaDeCategoria,
  ]);
  const [listaChamados, setListaChamados] = useState();
  const [removeLoading, setRemoveLoading] = useState(false);

  const [emailData, setEmailData] = useState({
    destinatario: '',
    assunto: 'Novo Chamado',
    body: ``,
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [showModalDet, setShowModalDet] = useState(false);
  const handleOpenDet = (value) => {
    setShowModalDet(true),
      loaderDataChamadosDetalhe(value.id),
      setChamadoDescricao(value.descricao),
      setChamadoTitulo(value.titulo);
    setIdChamadoSelecionado(value.id);
  };
  const handleCloseDet = () => {
    setShowModalDet(false);
  };

  const { addToast } = useToast();

  const handleChamados = () => {
    GetChamadosXSolicitantes(email).then((res) => {
      setListaChamados(res.value.chamadosX);
      setRemoveLoading(true);
    });
  };

  const handleSetores = () => {
    consultaSetores()
      .then((res) => {
        setListaSetores(res);
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro ao listar Setores !',
        });
      });
  };

  const HandleGetDados = () => {
    ChamadosStatusGet().then((r) => {
      setListaDeStatus(r);
    });
    ChamadoSituacaoGet().then((r) => {
      setListaDeSituacao(r);
    });
    ChamadosTiposGet().then((r) => {
      setListaDeTipo(r);
    });
    ChamadoCategoriaGet().then((r) => {
      setListaDeCategoria(r);
    });
  };

  useEffect(() => {
    HandleGetDados();
    handleChamados();
    handleSetores();
  }, [filtro]);

  const loaderDataChamadosDetalhe = (id) => {
    apiFabrica
      .get(`/ChamadoDetalheX/${id}`)
      .then((res) => {
        setListaChamadosDetalhes(res.data.value.chamadosDetalhes);
      })
      .catch((error) => {
        addToast({
          type: 'danger',
          title: 'Erro ao listar Detalhes do Chamado !' + error,
        });
      });
  };

  const closeChamado = (value) => {
    ChamadosXFecharPut(value)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Chamado fechado com Sucesso!',
        });
        handleChamados();
      })
      .catch((err) => {
        let errorMessage = 'Problema ao fechar o chamado';
        if (err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        } else if (err.response && err.response.data) {
          errorMessage = err.response.data;
        } else if (err.message) {
          errorMessage = err.message;
        }
        addToast({
          type: 'danger',
          title: 'Problema ao fechar o chamado',
          description: errorMessage,
        });
      })
      .finally(() => handleClose());
  };

  const submitNovoChamada = async (body) => {
    const formData = new FormData();

    formData.append('titulo', body.titulo);
    formData.append('urgencia', body.urgencia);
    formData.append('descricao', body.descricao);
    formData.append('emailsolicitante', body.solicitante);
    formData.append('imagem', body.imagem);
    formData.append('idSetor_Demandado', Number(body.setor.id));
    formData.append('idCategoria', Number(body.categoria.id));
    formData.append('idTipo', Number(body.tipo.id));

    await postChamadosX(formData)
      .then(() => {
        handleEnviarEmail(body);
        addToast({
          type: 'success',
          description: 'Sucesso ao registrar chamado',
        });
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          description: 'Falha ao registrar chamado',
        });
      })
      .finally(() => {
        handleChamados();
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleEnviarEmail(emailData);
  };

  const handleEnviarEmail = useCallback(async (value) => {
    const { nome, email } = useUsuarioLocal();
    const data = new Date().toLocaleTimeString('pt-BR');
    const assunto = 'Novo Chamado ' + data;
    const dataAtual = new Date();
    const anoAtual = dataAtual.getFullYear();
    const { titulo, urgencia, descricao } = value;

    const destinatario = value.setor.responsavel;

    const { data: responseSetores } = await axios.get(
      `https://apidev.gestaonaweb.com.br/api/Setores/${setor}`
    );

    const body = `<table
    align="center"
    role="presentation"
    cellspacing="0"
    cellpadding="0"
    border="0"
    width="100%"
    style="max-width: 37.5em"
  >
    <tr style="width: 100%">
      <td>
        <table
          style="
            background-color: rgb(190, 190, 190);
            display: flex;
            justify-content: center;
            aling-items: center;
            padding: 30px;
          "
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          role="presentation"
          width="100%"
        >
          <tbody>
            <tr>
              <td style="width="25%">
              
              
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style="
            border: 1px solid rgb(0, 0, 0, 0.1);
            border-radius: 3px;
            overflow: hidden;
          "
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          role="presentation"
          width="100%"
        >
          <tbody>
            <tr>
              <table
                width="100%"
                style="padding: 20px 40px; padding-bottom: 0"
                align="center"
                role="presentation"
                cellspacing="0"
                cellpadding="0"
                border="0"
              >
                <tbody style="width: 100%">
                  <tr style="width: 100%">
                    <td>
                      <p
                        style="
                          font-size: 14px;
                          line-height: 24px;
                          margin: 16px 0;
                          color: rgb(0, 0, 0, 0.5);
                          margin-top: -5px;
                        "
                      >
                        *${data}
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                     <b>Email/Nome: </b>  ${email} - ${nome}
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                     <b>Tipo: </b>  ${value.tipo.nome}
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                     <b>Categoria: </b>  ${value.categoria.nome}
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                     <b>Urgencia: </b>  ${urgencia} 
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                     <b>Setor Solicitante: </b>  ${responseSetores.setor}
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                     <b>Titulo: </b>  ${titulo}
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                     <b>Descricao: </b>  ${descricao}
                      </p>
                    </td>
                  </tr>
                </tbody>
                <tbody style="width: 100%">
                  <tr style="width: 100%">
                    <td>                
                      <p
                        style="font-size:12px;line-height:24px; 0;text-align:center;color:rgba(71, 71, 71, 0.7)"
                      >
                        © ${anoAtual} | REISTAR INDÚSTRIA E COMÉRCIO DE ELETRÔNICOS
                        LTDA
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </table>`;

    const newValue = { destinatario, body, assunto };

    EnviarFormEmail(newValue)
      .then((retorno) => {
        addToast({
          type: 'success',
          description: 'Sucesso ao enviar email',
        });
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          description: 'Falha ao enviar email',
        });
      });
  });

  return (
    <>
      <LayoutNovo setorColor={'geral'}>
        <XModalAbrirChamado
          open={open}
          onClose={handleClose}
          handleSubmit={submitNovoChamada}
          listaSetores={listaSetores}
          listaDeTipo={listaDeTipo}
          listaDeCategoria={listaDeCategoria}
        />
        <ModalDetalhesX
          chamadoDescricao={chamadoDescricao}
          chamadoTitulo={chamadoTitulo}
          open={showModalDet}
          OnClose={handleCloseDet}
          idChamadoSelecionado={idChamadoSelecionado}
          listaChamados={listaChamadosDetalhes}
        />

        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 2,
              '@media (max-width: 600px)': {
                flexDirection: 'column',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 2,
                textShadow: '-1px -1px 6px #000000',
                color: 'rgb(248, 249, 250)',
                p: [2],
              }}
            >
              <BsFillMegaphoneFill size={28} />
              <Typography variant="h4">Help Amvox - Meus Chamados</Typography>
            </Box>
            <Button
              variant="contained"
              color="error"
              sx={{
                '@media (max-width: 600px)': {
                  width: '90%',
                },
              }}
              startIcon={<AddIcon />}
              onClick={handleOpen}
            >
              Abrir Chamado
            </Button>
          </Box>
          <ContainerContact>
            <Box
              sx={{
                width: '100%',
                overflow: 'scroll',
                flexGrow: '3',
              }}
            >
              {removeLoading ? (
                <XTabelaChamadosUser2
                  handleCloseChamado={closeChamado}
                  data={listaChamados}
                  setShowModalDet={setShowModalDet}
                  showModal={handleOpenDet}
                />
              ) : (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={'100%'}
                  height={600}
                />
              )}
            </Box>
          </ContainerContact>
        </Box>
      </LayoutNovo>
    </>
  );
};

export default XContato;
