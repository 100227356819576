import { apiFabrica } from './apis';

export const buscarNotaFiscalPorNumero = async (numero) => {
  try {
    if (numero) {
      const result = await apiFabrica.get(`Entregas/AlterarEntregaExpedido/${numero}`);

      return Promise.resolve(result.data);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const buscarEntregasPorFiltros = async (filtro) => {
  try {
    const result = await apiFabrica.get(`Entregas`, { params: filtro });

    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
}
