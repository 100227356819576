import { apiFabrica } from './apis';

export const updateInspecaoFornecedor = async (id, alteracoes) => {
    try {
        const result = await apiFabrica.put(`RatingFornecedor/${id}`, alteracoes);
        return Promise.resolve(result.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getChamadoSituacao = async () => {
    try{
        const result = await apiFabrica.get(`ChamadoSituacao`)
        return  Promise.resolve(result.data);
    }catch(error){
        return Promise.reject(error);
    }
}

export const getResponsavelChamados = async () => {
    try{
        const result = await apiFabrica.get(`Equipe`)
        return  Promise.resolve(result.data);
    }catch(error){
        return Promise.reject(error);
    }
}

export const getChamadosCategoria = async () => {
    try{
        const result = await apiFabrica.get(`ChamadosCategoria`)
        return  Promise.resolve(result.data);
    }catch(error){
        return Promise.reject(error);
    }
}
export const getChamadosTipos = async () => {
    try{
        const result = await apiFabrica.get(`ChamadosTipos`)
        return  Promise.resolve(result.data);
    }catch(error){
        return Promise.reject(error);
    }
}

export const getChamadosStatus = async () => {
    try{
        const result = await apiFabrica.get(`ChamadosStatus`)
        return  Promise.resolve(result.data);
    }catch(error){
        return Promise.reject(error);
    }
}