import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import TitlePageV3 from '../../components/TitlePageV3';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import SidebarNovo from '../../components/LayoutNovo/SidebarNovo';
import Amvoxlogopng from '../../assets/Amvoxlogopng.png';
import './styles.css';
import CardApontamentoChamado from './CardApontamento';
import { GetApontamentoChamados } from '../../services/apontamentoChamados/apontamentoChamados.service';
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router';
import CardIndicatorRed from './CardIndicator';

export default function ApontamentoChamados() {
  const navigate = useNavigate();
  const email = 'tidesenvolvimento3@amvox.com.br';
  const [apontamentos, setApontamentos] = useState([]);

  const fetchApontamentos = async () => {
    try {
      GetApontamentoChamados(email).then((r) => {
        setApontamentos(r.value);
      });
    } catch (error) {
      console.error('Erro ao buscar apontamentos:', error);
    }
  };

  useEffect(() => {
    fetchApontamentos();
  }, []);

  return (
    <>
      <div className="principalApontamento">
        <div className="sidebar">
          <SidebarNovo />
        </div>
        <Box position={'relative'} sx={{ backgroundColor: '#F2F2F2' }} gap={1}>
          <Box
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              pl: 4,
            }}
          >
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeftIcon />
            </IconButton>
            <img src={Amvoxlogopng} alt="Amvox" className="imgAmvox"></img>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pr: 2,
              }}
            >
              <TitlePageV3 title={'Apontamento de Chamados'} />
            </Box>
          </Box>

          <Grid container sx={{ marginTop: '15px' }}>
            <Grid container sx={{ display: 'flex', width: 'auto' }}>
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  width: '590px',
                  height: '223px',
                  borderRadius: '16px',
                  gap: '34px',
                  ml: '34px',
                  boxShadow: '0px 1px 5.8px 0px rgba(0, 0, 0, 0.25)',
                  color: 'white',
                }}
              >
                <Box>
                  <CardIndicatorRed
                    numeroChamados={apontamentos.qtdAberto}
                    titulo="Chamados abertos"
                  />
                </Box>
                <Box>
                  <CardIndicatorRed
                    numeroChamados={apontamentos.qtdTotal}
                    titulo="Chamados total"
                  />
                </Box>
              </Box>
            </Grid>

            <Grid container sx={{ display: 'flex', width: 'auto' }}>
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  width: '590px',
                  height: '223px',
                  borderRadius: '16px',
                  gap: '34px',
                  ml: '20px',
                  mb: '30px',
                  boxShadow: '0px 1px 5.8px 0px rgba(0, 0, 0, 0.25)',
                  color: 'white',
                }}
              >
                <Box>
                  <CardIndicatorRed
                    numeroChamados={apontamentos.qtdFechado}
                    titulo="Chamados fechados"
                  />
                </Box>
                <Box>
                  <CardIndicatorRed
                    numeroChamados={apontamentos.qtdEmdia}
                    titulo="Em dia"
                  />
                </Box>
              </Box>
            </Grid>

            <Grid container xs={1} sx={{ display: 'flex', width: 'auto' }}>
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  width: '590px',
                  height: '223px',
                  borderRadius: '16px',
                  ml: '15px',
                  gap: '34px',
                  boxShadow: '0px 1px 5.8px 0px rgba(0, 0, 0, 0.25)',
                  color: 'white',
                }}
              >
                <Box sx={{ ml: '10px' }}>
                  <CardIndicatorRed
                    numeroChamados={apontamentos.qtdAtrasados}
                    titulo="Atrasados"
                  />
                </Box>
                <Box sx={{ mr: '10px' }}>
                  <CardIndicatorRed
                    numeroChamados={apontamentos.qtdNaoAtribuido}
                    titulo="Não atribuidos"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={3.5} md={1.97}>
              <CardApontamentoChamado
                numeroChamados={apontamentos.qtdEmdiaCompras}
                titulo="Compras em dia"
              />
            </Grid>
            <Grid item xs={3.5} md={1.97}>
              <CardApontamentoChamado
                numeroChamados={apontamentos.qtdAtrasadosqtdEmdiaCompras}
                titulo="Compras atrasados"
              />
            </Grid>
            <Grid item xs={3.5} md={1.97}>
              <CardApontamentoChamado
                numeroChamados={apontamentos.qtdEmdiaManutencao}
                titulo="Manutenção em dia"
              />
            </Grid>
            <Grid item xs={3.5} md={1.97}>
              <CardApontamentoChamado
                numeroChamados={apontamentos.qtdAtrasadosManutencao}
                titulo="Manutenção atrasados"
              />
            </Grid>
            <Grid item xs={3.5} md={1.97}>
              <CardApontamentoChamado
                numeroChamados={apontamentos.qtdEmdiaApoioUser}
                titulo="Apoio ao usuário em dia"
              />
            </Grid>
            <Grid item xs={3.5} md={1.97}>
              <CardApontamentoChamado
                numeroChamados={apontamentos.qtdAtrasadosApoioUser}
                titulo="Apoio ao usuário atrasados"
              />
            </Grid>
            <Grid item xs={3.5} md={1.97}>
              <CardApontamentoChamado
                numeroChamados={apontamentos.qtdEmdiaInfra}
                titulo="Infraestrutura em dia"
              />
            </Grid>
            <Grid item xs={3.5} md={1.97}>
              <CardApontamentoChamado
                numeroChamados={apontamentos.qtdAtrasadosInfra}
                titulo="Infraestrutura atrasados"
              />
            </Grid>
            <Grid item xs={3.5} md={1.97}>
              <CardApontamentoChamado
                numeroChamados={apontamentos.qtdEmdiaSistemas}
                titulo="Sistemas em dia"
              />
            </Grid>
            <Grid item xs={3.5} md={1.97}>
              <CardApontamentoChamado
                numeroChamados={apontamentos.qtdAtrasadosSistemas}
                titulo="Sistemas atrasados"
              />
            </Grid>
            <Grid item xs={3.5} md={1.97}>
              <CardApontamentoChamado
                numeroChamados={apontamentos.qtdEmdiaDesenvolvimento}
                titulo="Desenvolvimento em dia"
              />
            </Grid>
            <Grid item xs={3.5} md={1.97}>
              <CardApontamentoChamado
                numeroChamados={apontamentos.qtdAtrasadosDesenvolvimento}
                titulo="Desenvolvimento atrasados"
              />
            </Grid>
          </Grid>

          <Box
            margin={1}
            paddingTop={3}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
            pr={4}
            pl={4}
          ></Box>
        </Box>

        <Box
          sx={{
            borderTop: '1px solid #000',
            marginTop: '25px',
          }}
        ></Box>

        <footer className="footerPage">Amvox 2024</footer>
      </div>
    </>
  );
}
