import { Buscar, Post, Put } from '../core/apiFabrica.service';
import { endpointsPlanejamentoProducao } from './planejamento.endpoints';

export const consultaPlanejamento = async (filtro) =>
  await Buscar(endpointsPlanejamentoProducao.this, {
    params: filtro,
  });

export const calcularPlanejamentoProducao = async (value) => {
  try {
    const result = await Post(endpointsPlanejamentoProducao.calculo, value);
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const registrarPlanejamentoProducao = async (value) => {
  try {
    const result = await Post(endpointsPlanejamentoProducao.this, value);
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const atualizarPlanejamentoProducao = async (value) =>
  await Put(endpointsPlanejamentoProducao.this, value);
