import { HiUserGroup } from 'react-icons/hi';
import { ImEyePlus } from 'react-icons/im';
import { CgFileDocument } from 'react-icons/cg';

import {
  FaTruck,
  FaTruckLoading,
  FaFileInvoiceDollar,
  FaTools,
  FaBookOpen,
  FaBoxes,
} from 'react-icons/fa';
import InfoIcon from '@mui/icons-material/Info';
import { GrProductHunt } from 'react-icons/gr';
import {
  TbCash,
  TbFileInvoice,
  TbListCheck,
  TbNotebook,
  TbPackage,
  TbReportMoney,
  TbTableImport,
  TbPlaneInflight,
} from 'react-icons/tb';
import { MdNetworkCheck } from 'react-icons/md';
import {
  GiTakeMyMoney,
  GiFactory,
  GiHandTruck,
  GiSpikedDragonHead,
  GiExitDoor,
  GiVirtualMarker,
  GiCrossedChains,
  GiCargoShip,
  GiReturnArrow,
  GiBanknote,
} from 'react-icons/gi';
import { GoGlobe } from 'react-icons/go';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import { BsCashCoin, BsCashStack } from 'react-icons/bs';
import { TfiDropboxAlt } from 'react-icons/tfi';
import { LuPackageSearch } from 'react-icons/lu';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import QrCodeIcon from '@mui/icons-material/QrCode';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import PrecisionManufacturingTwoToneIcon from '@mui/icons-material/PrecisionManufacturingTwoTone';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import { FaHome } from 'react-icons/fa';
import { CiGrid42 } from 'react-icons/ci';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { CiViewTable } from 'react-icons/ci';
import { BsFillMegaphoneFill } from 'react-icons/bs';

export const ButtonsPagesPrincipal = [
  // Cards exclusivos do side bar
  {
    id: 2,
    img: <FaHome />,
    setorNome: 'Home',
    to: 'principal',
    setorAcesso: [1, 2, 9, 15, 7],
    nivelAcesso: [1, 6, 7, 8, 9],
    paginasDoSetor: [],
    notSeePrincipal: true,
  },
  {
    id: 41,
    img: <ReceiptIcon />,
    setorNome: 'Aprov/Cad de Notas',
    to: 'entradaprotocolonotas',
    setorAcesso: [1, 2, 4, 5, 8, 9, 7, 12, 15],
    nivelAcesso: [1, 6, 7, 8, 9],
    paginasDoSetor: [],
    notSeePrincipal: true,
  },
  {
    id: 3,
    img: <CiGrid42 />,
    setorNome: 'Dashboard',
    to: 'dashboard',
    setorAcesso: [1, 2, 9, 15, 7],
    paginasDoSetor: [],
    notSeePrincipal: true,
  },
  {
    id: 1,
    img: <BsFillMegaphoneFill />,
    setorNome: 'Meus Chamados',
    to: 'xcontato',
    setorAcesso: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28,
    ],
    paginasDoSetor: [],
    notSeePrincipal: true,
  },
  // Fim Cards exclusivos do side bar

  {
    id: 2,
    img: <FaFileInvoiceDollar />,
    setorNome: 'Comercial',
    idNome: 'comercial',
    setorAcesso: [3, 1, 2, 4, 5, 7, 15, 9],
    color: '#F6D258',
    paginasDoSetor: [
      {
        id: 1,
        img: <FaFileInvoiceDollar />,
        to: 'pedidos',
        label: 'Pedidos',
        setorAcesso: [1, 2, 5, 4, 7, 15],
      },
      {
        id: 2,
        img: <FaTruckLoading />,
        to: 'notasfiscaisemitidas',
        label: 'Notas Fiscais Emitidas',
        setorAcesso: [3, 1, 2, 7, 15, 9],
      },
    ],
  },
  {
    id: 4,
    img: <GiTakeMyMoney />,
    setorNome: 'Compra Int.',
    idNome: 'comercialInt',
    setorAcesso: [3, 2, 4, 8, 7, 9, 10, 11, 15, 18],
    nivelAcesso: [1, 6, 7, 8, 9],
    color: '#54F070',
    paginasDoSetor: [
      {
        id: 1,
        img: <GiTakeMyMoney />,
        to: 'margens',
        label: 'Margens',
        setorAcesso: [3, 2, 8, 7, 15, 18],
        nivelAcesso: [6, 7, 8],
      },
      {
        id: 2,
        img: <GiVirtualMarker />,
        to: 'virtualsupply',
        label: 'Virtual Supply',
        setorAcesso: [2, 3, 8, 7, 15, 18],
      },
      {
        id: 3,
        img: <GiSpikedDragonHead />,
        to: 'registrofornecedoreschina',
        label: 'Registro Fornecedores',
        setorAcesso: [2, 8, 7, 15, 11, 18],
        nivelAcesso: [6, 7, 8, 9],
      },
      {
        id: 4,
        img: <ImEyePlus />,
        to: 'inspecao',
        label: 'Inspeção',
        setorAcesso: [2, 8, 7, 15, 18],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 5,
        img: <TbNotebook />,
        to: 'fornecedores',
        label: 'Ranking de Fornecedores',
        setorAcesso: [2, 10, 9, 4, 8, 7, 15, 11],
        nivelAcesso: [6, 7, 8],
      },
    ],
  },
  {
    id: 6,
    img: <FaBoxes />,
    setorNome: 'Estoque',
    idNome: 'estoque',
    setorAcesso: [1, 3, 5, 7, 9, 15, 2, 10],
    color: '#8A4601',
    paginasDoSetor: [
      {
        id: 1,
        img: <FaBoxes />,
        to: 'estoque',
        label: 'Estoque de produtos',
        setorAcesso: [1, 3, 5, 7, 9, 15, 2, 10],
      },
      {
        id: 2,
        img: <LuPackageSearch />,
        to: 'checagemnotaestoque',
        label: 'Checagem de Nota',
        setorAcesso: [3, 2, 9, 15, 7],
      },
      {
        id: 2,
        img: <LocalParkingIcon />,
        to: 'taxadeocupacao',
        label: 'Taxa De Ocupação',
        setorAcesso: [3, 1, 2, 7, 9, 15],
      },
    ],
  },
  {
    id: 9,
    img: <TbReportMoney />,
    setorNome: 'Financeiro',
    idNome: 'financeiro',
    setorAcesso: [1, 2, 4, 5, 8, 9, 7, 15],
    nivelAcesso: [1, 6, 7, 8, 9],
    color: '#333D29',
    paginasDoSetor: [
      {
        id: 1,
        img: <TbReportMoney />,
        to: 'financeiro',
        label: 'Financeiro',
        setorAcesso: [2, 4, 7, 15],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 2,
        img: <HiUserGroup />,
        to: 'clientesjoin',
        label: 'Cliente Com Vendedores',
        setorAcesso: [1, 2, 4, 5, 8, 9, 7, 12, 15],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 3,
        img: <BsCashStack />,
        to: 'titulospagar',
        label: 'Títulos a Pagar',
        setorAcesso: [2, 4, 7, 15],
        nivelAcesso: [6, 7, 8, 9],
      },
      {
        id: 4,
        img: <BsCashCoin />,
        to: 'titulosreceber',
        label: 'Títulos a Receber',
        setorAcesso: [2, 4, 7, 15],
        nivelAcesso: [6, 7, 8, 9],
      },
      {
        id: 5,
        img: <TbNotebook />,
        to: 'protocolodenotas',
        label: 'Protocolo de Notas',
        setorAcesso: [1, 2, 4, 5, 7, 11, 15],
        nivelAcesso: [1, 6, 7, 8],
      },
    ],
  },
  {
    id: 16,
    img: <TbFileInvoice />,
    setorNome: 'Fiscal',
    idNome: 'fiscal',
    setorAcesso: [4, 5, 7],
    nivelAcesso: [1, 6, 7, 8, 9],
    color: '#0088A3',
    paginasDoSetor: [
      {
        id: 1,
        img: <GiBanknote />,
        to: 'faturamento',
        label: 'Faturamento',
        setorAcesso: [4, 5, 7],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 1,
        img: <GiBanknote />,
        to: 'Fiscal',
        label: 'Notas Fiscais',
        setorAcesso: [4, 5, 7],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
    ],
  },
  {
    id: 15,
    img: <GiCargoShip />,
    setorNome: 'Importação',
    idNome: 'importacao',
    setorAcesso: [2, 8, 7, 15, 18],
    nivelAcesso: [1, 6, 7, 8, 9],
    color: '#ffffff',
    paginasDoSetor: [
      {
        id: 1,
        img: <GoGlobe />,
        to: 'importacao',
        label: 'Acomp. de Navios',
        setorAcesso: [2, 8, 7, 15, 18],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 2,
        img: <TbTableImport />,
        to: 'import_c7_entrada',
        label: 'Entrada de Importação',
        setorAcesso: [2, 8, 7, 15, 18],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
    ],
  },
  {
    id: 1,
    img: <FaTruck />,
    setorNome: 'Transporte',
    idNome: 'Transporte',
    setorAcesso: [1, 2, 9, 15, 7, 11],
    nivelAcesso: [1, 6, 7, 8, 9],
    color: '#56A0F5',
    paginasDoSetor: [
      {
        id: 1,
        img: <FaTruck />,
        to: 'entregas',
        label: 'Entregas',
        setorAcesso: [1, 2, 9, 15, 7, 11],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 2,
        img: <TbListCheck />,
        to: 'expedicaolog',
        label: 'Check de Expedição',
        setorAcesso: [2, 9, 15, 7, 11],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 4,
        img: <TbPackage />,
        to: 'recebimentopermanenciacontainer',
        label: 'Recebimento Container',
        setorAcesso: [1, 2, 9, 15, 7, 11],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 5,
        img: <TbPlaneInflight />,
        to: 'painelaeroporto',
        label: 'Painel Aeroporto',
        setorAcesso: [1, 2, 9, 15, 7, 11],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 6,
        img: <TextSnippetIcon />,
        to: 'agendamentocarga',
        label: 'Agendamento de Carga',
        setorAcesso: [1, 2, 7, 9, 15, 11],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
    ],
  },
  {
    id: 5,
    img: <GiHandTruck />,
    setorNome: 'Pós-venda',
    idNome: 'posvenda',
    setorAcesso: [2, 11, 15, 7, 9],
    nivelAcesso: [1, 6, 7, 8, 9],
    color: '#845EC2',
    paginasDoSetor: [
      {
        id: 1,
        img: <GiHandTruck />,
        to: 'coletaeentrega',
        label: 'Coleta Entrega',
        setorAcesso: [2, 11, 15, 7, 9],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 2,
        img: <GiReturnArrow />,
        to: 'custoderetorno',
        label: 'Custo de Retorno',
        setorAcesso: [2, 11, 15, 7, 9],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 3,
        img: <DataUsageIcon />,
        to: 'indicadoresnps',
        label: 'Indicadores Nps',
        setorAcesso: [2, 11, 15, 7, 9],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 4,
        img: <PrecisionManufacturingTwoToneIcon />,
        to: 'fornecimentodepecas',
        label: 'Fornecimento de peças',
        setorAcesso: [2, 11, 15, 7, 9],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
    ],
  },
  {
    id: 8,
    img: <MdNetworkCheck />,
    setorNome: 'Produção',
    idNome: 'producao',
    setorAcesso: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 20],
    nivelAcesso: [1, 6, 7, 8, 9],
    color: '#FF8C00',
    paginasDoSetor: [
      {
        id: 1,
        img: <MdNetworkCheck />,
        to: 'apontamentos',
        label: 'Apontamentos',
        setorAcesso: [10, 7, 15, 2],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 2,
        img: <GrProductHunt />,
        to: 'produtos',
        label: 'Produtos',
        setorAcesso: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20],
        nivelAcesso: [1, 6, 8, 9],
      },
      {
        id: 3,
        img: <GiFactory />,
        to: 'producoes',
        label: 'Produções',
        setorAcesso: [10, 7, 15, 2],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 4,
        img: <GiCrossedChains />,
        to: 'movimentacaocorrente',
        label: 'Movimentacao Corrente',
        setorAcesso: [2, 20, 10, 7, 15],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 5,
        img: <QrCode2Icon />,
        to: 'geradorqrcode',
        label: 'Gerador QrCode',
        setorAcesso: [2, 20, 10, 7, 15],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 6,
        img: <QrCodeIcon />,
        to: 'registroqrcode',
        label: 'Registro QRcode',
        setorAcesso: [2, 20, 10, 7, 15],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 7,
        img: <SpaceDashboardIcon />,
        to: 'dashproducao',
        label: 'Dashboard Produção',
        setorAcesso: [2, 20, 10, 7, 15],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 8,
        img: <CiViewTable />,
        to: 'planejamentoproducao',
        label: 'Planejamento Produção',
        setorAcesso: [2, 20, 10, 7, 15],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 9,
        img: <BackupTableIcon />,
        to: 'tabelaMestra',
        label: 'Tabela Mestra',
        setorAcesso: [2, 20, 10, 7, 15],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
      {
        id: 10,
        img: <AlignHorizontalRightIcon />,
        to: 'eficienciaWeb',
        label: 'Eficiência Web',
        setorAcesso: [2, 20, 10, 7, 15],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
    ],
  },

  {
    id: 10,
    img: <GiExitDoor />,
    setorNome: 'Portaria',
    idNome: 'portaria',
    setorAcesso: [2, 6, 7, 15],
    nivelAcesso: [1, 8],
    color: '#000000',
    paginasDoSetor: [
      {
        id: 1,
        img: <GiExitDoor />,
        to: 'notassaida',
        label: 'Notas de Saída',
        setorAcesso: [2, 16, 7, 15],
        nivelAcesso: [1, 8],
      },
    ],
  },

  {
    id: 40,
    img: <ManageAccountsIcon />,
    setorNome: 'Protótipo',
    idNome: 'prototipo',
    setorAcesso: [2, 6, 7, 15],
    nivelAcesso: [1, 8],
    color: '#f542dd',
    paginasDoSetor: [
      {
        id: 1,
        img: <TextSnippetIcon />,
        to: 'expedicaologistica',
        label: 'Remessa de Notas',
        setorAcesso: [1, 2, 7, 9, 15],
        nivelAcesso: [1, 6, 7, 8, 9],
      },
    ],
  },
  {
    id: 14,
    img: <TfiDropboxAlt />,
    setorNome: 'Recepção',
    idNome: 'recepcao',
    setorAcesso: [7],
    nivelAcesso: [1, 8],
    color: '#FF8FAB',
    paginasDoSetor: [
      {
        id: 1,
        img: <TfiDropboxAlt />,
        to: 'insumosSaldo',
        label: 'Insumos',
        setorAcesso: [2, 7, 6],
        nivelAcesso: [1, 6, 7, 8],
      },
    ],
  },
  {
    id: 12,
    img: <CgFileDocument />,
    setorNome: 'Secretariado',
    idNome: 'secretariado',
    setorAcesso: [2, 15, 7],
    nivelAcesso: [6, 7, 8],
    color: '#999999',
    paginasDoSetor: [
      {
        id: 1,
        img: <CgFileDocument />,
        to: 'contratos',
        label: 'Contratos',
        setorAcesso: [2, 15, 7],
        nivelAcesso: [6, 7, 8],
      },
    ],
  },
  {
    id: 30,
    img: <FaTools />,
    setorNome: 'Inteligência',
    idNome: 'inteligencia',
    setorAcesso: [7],
    nivelAcesso: [8],
    color: '#001E3F',
    paginasDoSetor: [
      {
        id: 1,
        img: <FaTools />,
        to: 'inteligencia',
        label: 'Geral',
        setorAcesso: [7],
        nivelAcesso: [8],
      },
      {
        id: 2,
        img: <InfoIcon />,
        to: 'patchNotes',
        label: 'Patch Notes',
        setorAcesso: [7],
        nivelAcesso: [8],
      },
    ],
  },
  {
    id: 31,
    img: <FaBookOpen />,
    setorNome: 'Catálogo Amvox',
    idNome: 'catalogo',
    setorAcesso: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ],
    nivelAcesso: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    color: '#45B39D',
    paginasDoSetor: [
      {
        id: 1,
        img: <FaBookOpen />,
        to: 'catalogo',
        label: 'Catálogo',
        setorAcesso: [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20,
        ],
        nivelAcesso: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      },
    ],
  },
];
