import React, { useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';

import { Box } from '@mui/material';
import RegistrarQuantidadeProduzida from './ModalQTProduzido';
import moment from 'moment';

const TabelaPlanejamento = ({ listPlanejamento, handleClose }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'semana_Ano',
        header: 'Semanas',
        size: 100,
      },
      {
        accessorKey: 'periodoInicio',
        header: 'Periodo Inicio',
        size: 100,
        Cell: ({ cell }) => {
          return <p>{moment(cell.getValue()).format('DD/MM/yyyy')}</p>;
        },
      },
      {
        accessorKey: 'periodoFim',
        header: 'Periodo Fim',
        size: 100,
        Cell: ({ cell }) => {
          return <p>{moment(cell.getValue()).format('DD/MM/yyyy')}</p>;
        },
      },
      {
        accessorKey: 'quantidadeProgramacao',
        header: 'Quantidade Programada',
        size: 100,
      },
      {
        accessorKey: 'horasNecessarias',
        header: 'Hrs Prod. Necessarias',
        size: 100,
      },
      {
        accessorKey: 'horasdisponiveis',
        header: 'Hrs. Disp',
        size: 100,
      },
      {
        accessorKey: 'capacidadeutilizada',
        header: 'Capacidade utilizada',
        size: 150,
        // logica de mudar as cores aqui
        // Cell: ({ cell }) => {
        //   return (
        //     <Box
        //       sx={{
        //         display: 'flex',
        //         maxHeight: '200%',
        //         justifyContent: 'center',
        //         bgcolor: 'red',
        //       }}
        //     >
        //       {cell.getValue()}
        //     </Box>
        //   );
        // },
      },
      {
        accessorKey: 'horasDisponiveisDiariamente',
        header: 'Horas Disponiveis/Dia',
        size: 100,
      },
      {
        accessorKey: 'linhasDisponiveis',
        header: 'Linhas Disponiveis',
        size: 100,
      },
      {
        accessorKey: 'diasDisponiveis',
        header: 'Dias Disponiveis',
        size: 100,
      },
      {
        accessorKey: 'quantidadeProduzida',
        header: 'Quantidade Produzida',
        size: 100,
        Cell: ({ cell }) => {
          return (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  fontWeight: 'bold',
                }}
              >
                {cell.getValue()}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <RegistrarQuantidadeProduzida
                  handleUpdate={handleClose}
                  value={cell.row.original}
                />
              </Box>
            </>
          );
        },
      },
      {
        accessorKey: 'observacao',
        header: 'Observacao',
        size: 100,
      },
    ],
    []
  );

  return <MaterialReactTable columns={columns} data={listPlanejamento} />;
};

export default TabelaPlanejamento;
