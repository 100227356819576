import { apiFabrica } from './apis';

export const BuscarProdutoMetahoraGet = async () => {
  try {
    const result = await apiFabrica.get(`ProdutosMetaHora`);

    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const ProdutoMetahoraPost = async (body) => {
  try {
    const result = await apiFabrica.post(`ProdutosMetaHora`, body);
    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
