import React, { useEffect, useState } from 'react';
import LayoutNovo from '../../../components/LayoutNovo';
import Box from '@mui/material/Box';
import TitlePage from '../../../components/TitlePage';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Grid, Button } from '@mui/material';

import NumberBoxV4 from '../../../components/NumberBoxV4';
import WalletBoxV4 from '../../../components/WalletBoxV4';

import TabelaPlanejamento from './tabela';
import { Add } from '@mui/icons-material';
import { consultaProdutos } from '../../../services/produtos/produtos.service';
import ModalRegistro from './ModalRegistroPlanejamento';
import { useToast } from '../../../hooks/components/toast.hook';
import { consultaPlanejamento } from '../../../services/planejamentoSemanalProducao/planejamento.service';

export function PlanejamentoProducao() {
  const [produtosLista, setProdutosLista] = useState([]);
  const [planejamentoproducaoLista, setPlanejamentoProducaoLista] = useState(
    []
  );
  const [openModalRegistro, setOpenModalRegistro] = useState(false);
  const [openModalQtd, setOpenModalQtd] = useState(false);

  const handleShowModalRegistro = () => {
    setOpenModalRegistro(!openModalRegistro);
    atualizarPagina();
  };

  const { addToast } = useToast();

  useEffect(() => {
    consultaProdutos()
      .then((res) => {
        setProdutosLista(res);
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro ao consultar produtos',
        });
      });

    consultaPlanejamento()
      .then((res) => {
        setPlanejamentoProducaoLista(res);
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro ao consultar planejamento',
        });
      });
  }, []);

  const trueAtualiza = () => {
    atualizarPagina();
  };

  function atualizarPagina() {
    consultaPlanejamento()
      .then((res) => {
        setPlanejamentoProducaoLista(res);
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro ao consultar planejamento',
        });
      });
  }

  return (
    <LayoutNovo style={{ zIndex: '-10' }} setorColor={'producao'}>
      <ModalRegistro
        openModal={openModalRegistro}
        handleClose={handleShowModalRegistro}
        produtosLista={produtosLista}
      />

      <Box position={'relative'}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pr: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              pr: 2,
            }}
          >
            <TitlePage
              title={'Planejamento Produção'}
              iconChildren={
                <MonetizationOnIcon fontSize="large" color="#ffffff" />
              }
            />
          </Box>
          <Grid item xs={6} sm={6} md={1}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleShowModalRegistro}
              color="success"
              size="large"
              startIcon={<Add />}
              type="reset"
            >
              Registrar
            </Button>
          </Grid>
        </Box>

        <Grid container columnSpacing={2} px={1} py={1} gap={0.5}>
          <Grid item sx={6} md={4.5} xs={12} sm={12}>
            <NumberBoxV4 colorPrimary={'#f6d258'} title={'Executado'} />
          </Grid>
          <Grid item sx={6} md={4.5} xs={12} sm={12}>
            <WalletBoxV4
              colorPrimary={'#008000'}
              title={'Quantidade Produzida'}
            />
          </Grid>
        </Grid>
      </Box>
      <TabelaPlanejamento
        setOpenModalQtd={setOpenModalQtd}
        listPlanejamento={planejamentoproducaoLista}
        handleClose={trueAtualiza}
      />
    </LayoutNovo>
  );
}
