import axios from 'axios';

import { isAuthenticated, getToken } from './auth';

export const apiAlunos = axios.create({
  baseUrl: process.env.REACT_APP_API_ALUNOS,
});

export const apiFabrica = axios.create({
  baseUrl: process.env.REACT_APP_API_FABRICA,
});
export const apiNotaFiscal = axios.create({
  baseURL: process.env.REACT_APP_API_NOTAFISCAL,
});
export const apiCotacao = axios.create({
  baseUrl: process.env.REACT_APP_API_COTACAO,
});

export const apiIPCA = axios.create({
  baseUrl: `https://api.bcb.gov.br/dados/serie/bcdata.sgs.433/dados/ultimos/1?formato=json`,
})

export const apiMunicipiosIBGE = axios.create({
  baseUrl: `https://servicodados.ibge.gov.br/api/v1/localidades`,
});


const dataSelic = new Date(new Date().setDate(new Date().getDate() - 1));
const dataSelicFormatada = dataSelic.toLocaleDateString();

export const apiSelic = axios.create({
  baseURL: `https://api.bcb.gov.br/dados/serie/bcdata.sgs.1178/dados/?formato=json&dataInicial=${dataSelicFormatada}&dataFinal=${dataSelicFormatada}`
})

apiAlunos.defaults.baseURL = process.env.REACT_APP_API_ALUNOS;
apiFabrica.defaults.baseURL = process.env.REACT_APP_API_FABRICA;
apiFabrica.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
};

apiCotacao.defaults.baseURL = 'https://economia.awesomeapi.com.br/json';
apiSelic.defaults.baseURL = `https://api.bcb.gov.br/dados/serie/bcdata.sgs.1178/dados/?formato=json&dataInicial=${dataSelicFormatada}&dataFinal=${dataSelicFormatada}`;
apiMunicipiosIBGE.defaults.baseURL = 'https://servicodados.ibge.gov.br/api/v1/localidades'
apiIPCA.defaults.baseURL = 'https://api.bcb.gov.br/dados/serie/bcdata.sgs.433/dados/ultimos/1?formato=json'
apiFabrica.interceptors.request.use(async (config) => {
  if (isAuthenticated()) {
    if (config !== null && config.headers)
      config.headers.Authorization = `Bearer ${getToken()}`;
  }

  return config;
});

// apiFabrica.interceptors.response.use(
//   async (status) => {
//     return Promise.resolve(status);
//   },
//   async (error) => {
//     const response = error.response;

//     if (response.status)
//       if (response.status === 401) window.location.href = '/401';

//     return Promise.reject(error);
//   }
// );

export const apiParaTestesDev = axios.create({
  baseURL: "http://localhost:3333"
})