import { Buscar, BuscarPorGuid, Delete, Post, Put } from '../core/apiFabrica.service';
import { chamadosDetalheXEndpoints, chamadosDetalheXEndp } from './chamadosDetalheX.endpoints';

// export const GetChamadosDetalhe = async (request) =>
//     await Buscar(chamadosDetalheEndpoints.this, { params: request });

export const GetChamadosDetalhe = async (request) =>
    await Buscar(chamadosDetalheXEndpoints.this, { params: request });


export const PostChamadosDetalhe = async (body) =>
    await Post(chamadosDetalheXEndpoints.this, body)


export const PutChamadosDetalhe = async (body) =>
    await Put(chamadosDetalheXEndpoints.this, body)


export const GetChamadosDetalheId = async (id) =>
    await BuscarPorGuid(chamadosDetalheXEndpoints.this, id)


export const DeleteChamadosDetalheId = async (id) =>
    await Delete(chamadosDetalheXEndpoints.this, id)

