import { apiFabrica } from './apis';

export const getProdutosForaLinha = async (filtro) => {
  try {
    const result = await apiFabrica.get(`ProdutosForaLinha`, { params: filtro });

    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const atualizarImageForaLinha = async (body) => {
  try {
    const result = await apiFabrica.patch(`/ProdutosForaLinha`, body);
    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
