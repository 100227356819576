import { Buscar, BuscarPorGuid, Post, Put } from '../core/apiFabrica.service';
import { chamadosXEndpoints } from './chamadosX.endpoints';
import { apiFabrica } from "../apis";

export const GetChamados = async (body) =>
    await Buscar(chamadosXEndpoints.this, { EmailUsuario: body });

export const GetChamadosXFiltrado = async (filtro) => {
    try {
        const result = await apiFabrica.get('ChamadosX', { params: filtro });
        return Promise.resolve(result.data);
    } catch (error) {
        return Promise.reject(error);
    }
}

export const postChamadosX = async (value) => {
    try {
        const result = await apiFabrica.post(`ChamadosX`, value)
        return Promise.resolve(result.data);
    } catch (error) {
        return Promise.reject(error);
    }
}

export const putChamadosX = async (value) => {
    try {
        const result = await apiFabrica.put(`ChamadosX`, value)
        return Promise.resolve(result.data);
    } catch (error) {
        return Promise.reject(error);
    }
}

export const PutChamados = async (body) =>
    await Put(chamadosXEndpoints.this, body)

export const GetChamadosXId = async (id) =>
    await BuscarPorGuid(chamadosXEndpoints.this, id)

export const GetChamadosXSolicitantes = async (Emailsolicitante) =>
    await BuscarPorGuid(chamadosXEndpoints.solicitante, Emailsolicitante)
