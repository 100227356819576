import { apiFabrica } from "./apis";

export const buscarInsumosMovimentacaoPorFiltro = async (filtro) => {
    try{
        const result = await apiFabrica.get('InsumosMovimentacao', { params: filtro })
        return  Promise.resolve(result.data);
    }catch(error){
        return Promise.reject(error);
    }
}
export const registrarInsumosMovimentacao = async (value) => {
    try{
        const result = await apiFabrica.post('InsumosMovimentacao', value) 
        return  Promise.resolve(result.data);
    }catch(error){
        return Promise.reject(error);
    }
}

