import { Buscar, Put } from '../core/apiFabrica.service';
import { endpointsProdutos } from './produto.endpoints';
import { apiFabrica } from '../apis';

export const consultaProdutos = async (filtro) =>
  await Buscar(endpointsProdutos.semImagem, {
    params: filtro,
  });

export const consultaProdutosImagem = async (filtro) =>
  await Buscar(endpointsProdutos.this, {
    params: filtro,
  });

export const buscarPosVendaEntregaItem = async (filtro) => {
  try {
    const result = await apiFabrica.get(
      `PosVendaEntregaClientes/EntregaClienteItem`,
      { params: filtro }
    );
    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const buscarPorEanProduto = async (filtro) =>
  await Buscar(endpointsProdutos.produtoEan, filtro);

export const registrarProduto = async (value) =>
  await Put(endpointsProdutos.this, value);
