import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { TextField } from '@mui/material';
dayjs.locale('pt-br');

export default function InputDateAmvoxC({
  label,
  dataGet,
  formatData = 'YYYY-MM-DD',
  ...rest
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>

        <DatePicker
          value={dataGet ? dayjs(dataGet, formatData).toDate() : null}
          {...rest}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              size="small"
              fullWidth
              sx={{
                bgcolor: 'transparent', 
                borderRadius: 2,
                
              }}
            />
          )}
        />
    </LocalizationProvider>
  );
}
