import { apiFabrica } from './apis';

export const buscarMembroEquipe = async () => {
    try {
        const result = await apiFabrica.get(`Equipe`);
        return Promise.resolve(result.data);
    } catch (error) {
        return Promise.reject(error);
    }
};
export const adicionarMembroEquipe = async (value) => {
    try {
        const result = await apiFabrica.post(`Equipe`, value);
        return Promise.resolve(result.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

