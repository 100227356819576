import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import LayoutNovo from '../../components/LayoutNovo';
import TitlePage from '../../components/TitlePage';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { buscarEntregasPorFiltros } from '../../services/entregas.service';
import { useToast } from '../../hooks/components/toast.hook';
import TabelaDeEntregas from './components/TabelaDeEntregas';
import './styles.css';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { formatDateToHtmlMonth } from '../../utils/formatDate';
import debounce from '../../utils/debounce';
import NumberBox from '../../components/NumberBox';
import DeleteIcon from '@mui/icons-material/Delete';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import formatDateTotvs from '../../utils/formatDataTotvs';
import BreadCrump from '../../components/BreadCumbs';
import BarcodeNotaFiscalConfirmacao from '../../components/BarcodeNotaFiscalConfirmacao';
import NotasConfirmacao from '../../components/BarcodeNotaFiscalConfirmacao/Tabela';
import { useEntregas } from '../../hooks/entrega/entregas.hook';
import ExcelEntregasButton from './components/ExcelEntregasButton';
import { apiFabrica } from '../../services/apis';
import useUsuarioLocal from '../../hooks/usuarioLocal.hook';
import GuiaUtilizacao from '../../components/GuiaUtilizacao';
export default function EntregasTeste() {
  const { email } = useUsuarioLocal();
   const [filtro, setFiltro] = useState({
    documento: null,
    nome: null,
    datainicio: null,
    datafim: null,
    emissaoInicio: formatDateToHtmlMonth(new Date()),
    emissaoFim: null,
    previsaoInicio: null,
    previsaoFim: null,
    saidaInicio: null,
    saidaFim: null,
    classificFrete: null,
    romaneio: null,
  });
  const [guiaUtil, setGuiaUtil] = useState({
    campoFiltro: '#campoFiltro',
    camposData: '#camposData ',
    campoEmissao: '#campoEmissao ',
    campoBotoes: '#campoBotoes ',
    infoEntregas: '#infoEntregas ',
    infoProducao: '#infoProducao ',
     tabela: '#tabela ',
  });

  const guiaUtilConfig = {
    NotaFiscal: {
      selector: guiaUtil.campoFiltro,
      content:
        'Esse campo permite realizar busca de entregas através do n° da Nota Fiscal, Nome do Emissor, Romaneio e também por classificação.',
    },
    camposData: {
      selector: guiaUtil.camposData,
      content:
        'Esse campo permite realizar busca baseando-se no dia/mês ou emissão, previsão e saída.',
    },
    campoEmissao: {
      selector: guiaUtil.campoEmissao,
      content:
        'Esse campo permite realizar busca de entregas baseando-se em um intervalo de datas(Início de emissão e Fim de emisão).',
    },
    campoBotoes: {
      selector: guiaUtil.campoBotoes,
      content:
        'Esse campo contém os botões "Clear(resetar os filtros)", "Leitor de Código de Barra" e "Excel"(Você pode exportar os dados da tabela).',
    },
    infoEntregas: {
      selector: guiaUtil.infoEntregas,
      content:
        'Esse campo permite visualizar os dados referentes a Entregas, Média, A Sair e A entregar do dia ou dos intervalos.',
    },
    infoProducao: {
      selector: guiaUtil.infoProducao,
      content:
        'Esse campo permite visualizar a data de Produção, a data de Carregamento, a Previsão de Entregas e a Data de Entrega.',
    },
  
    tabela: {
      selector: guiaUtil.tabela,
      content:
        'Essa tela permite a vizualiação da tabela com os dados filtrados.',
    },
  };
  const [fluxoEntrega, setFluxoEntrega] = useState({
    dataProducao: 'Sem Data',
    dataCarregamento: 'Sem Data',
    dataTransporte: 'Sem Data',
    dataEntregue: 'Sem Data',
  });
  const [renderOverBreadCrumb, setRenderOverBreadCrumb] = useState({});
  const [entregaLista, setEntregaLista] = useState({
    table: [],
    excelAndCard: [],
  });
  const [loading, setLoading] = useState(true);
  const [styleAnime, setStyleAnime] = useState('')
  const [show, setShow] = useState(false);
  const [updateExpedicao, setUpdateExpedicao] = useState({
    expedido: 0,
    useLog: email + ' | ' + new Date().toLocaleString(),
  });

  const { buscarEntrega, isLoading } = useEntregas();
  const handleClose = useCallback(() => setShow(false));

  const classificFreteLista = [
    { label: '1 - Frete de Venda', value: '0001' },
    { label: '2 - Frete Pos-Venda', value: '0002' },
    { label: '3 - Venda Funcionario', value: '0003' },
    { label: '4 - Retirada Fob/Fratos', value: '0004' },
    { label: '5 - Bonificação MKT', value: '0005' },
  ];

  const { addToast } = useToast();

  const changeDateType = (state, action) => {
    switch (action) {
      case 'month':
        return 'month';
      case 'date':
        return 'date';
    }
  };

  const changeFluxoEntregaType = (state, action) => {
    switch (action) {
      case 'emissao':
        return { type: 'emissao', label: 'Emissão' };
      case 'previsao':
        return { type: 'previsao', label: 'Previsão' };
      case 'saida':
        return { type: 'saida', label: 'Saida' };
    }
  };

  const [dateType, setDateType] = useReducer(changeDateType, 'date');
  const [fluxoEntregaType, setFluxoEntregaDateType] = useReducer(
    changeFluxoEntregaType,
    { type: 'emissao', label: 'Emissão' }
  );
 
  const handleFetch = useCallback(() => {
    buscarEntregasPorFiltros(filtro)
      .then((res) => {
        setEntregaLista({
          table: res.data,
          excelAndCard: res,
        });
        if (res.data.length > 0) {
          setFluxoEntrega({
            dataProducao: formatDateTotvs(res.data[0].emissao, '/'),
            dataCarregamento: formatDateTotvs(res.data[0].saida, '/'),
            dataTransporte: formatDateTotvs(res.data[0].previsao, '/'),
            dataEntregue: formatDateTotvs(res.data[0].entregue, '/'),
          });
        }
      })
      .catch(() =>
        addToast({
          type: 'danger',
          title: '',
          description:
            'Erro grave - não carregou a tabela ou Nota não existe!!',
        })
      )
      .finally(() => setLoading(false));
  }, [filtro]);

  const handleClear = (e) => {
    e.target.reset();
    e.preventDefault();
    setFiltro({
      documento: null,
      cnpj: null,
      nome: null,
      dias: null,
      datainicio: null,
      emissaoInicio: formatDateToHtmlMonth(new Date()),
      emissaoFim: null,
      previsaoInicio: null,
      previsaoFim: null,
      saidaInicio: null,
      saidaFim: null,
      datafim: null,
      classificFrete: null,
      romaneio: null,
    });
    setDateType('date');
    setFluxoEntregaDateType('emissao');
    setRenderOverBreadCrumb({});
  };

  const handleNewRender = (values) => {
    setRenderOverBreadCrumb(values);
    if (classAnimation === '') {
      // setClassAnimation('render-Bread');
    }
  };

 

  const selectedValues = useMemo(
    () => classificFreteLista.filter((v) => v.label),
    // () => setFiltro({
    //   ...filtro,
    //   classificFrete: event.target.value,
    // })
    [classificFreteLista]
  );

  const leituraFiltroNotaFiscal = filtro.documento;


  const handleChangeExpedicao = async (id) => {
    if (id) {
      setUpdateExpedicao({
        expedido: 1,
        ...updateExpedicao,
      });
    }
    try {
      await apiFabrica.put(`/Entregas/${id}`, {
        ...updateExpedicao,
      });
      addToast({
        type: 'success',
        title: 'Status Expedido - Atualizado',
        description: `Alteração da NF ${id} bem sucedida`,
      });
    } catch (err) {
      addToast({
        type: 'danger',
        title: 'ERRO - Não Enviado',
        description: 'Erro ao tentar alterar o status de expedição!',
      });
    } finally {
      handleFetch();
    }
  };

  useEffect(() => {
    handleFetch();
  }, [filtro]);

   
  const [classAnimation, setClassAnimation] = useState('');

  const novaRenderizacao = (values) => {
    setRenderOverBreadCrumb(values);
    if (classAnimation === '') {
      setClassAnimation('render-Bread');
    }
  };

  const restartRenderizacao = useCallback(() => {
    setClassAnimation('');
  }, []);
 

  useEffect(() => {
    setStyleAnime('render-Bread');
  }, [renderOverBreadCrumb?.notaFiscal])
  
  return (
    <LayoutNovo setorColor={'logistica'}>
      <BarcodeNotaFiscalConfirmacao
        handleClose={handleClose}
        isLoading={isLoading}
        isShow={show}
        fn={buscarEntrega}
      >
        <NotasConfirmacao />
      </BarcodeNotaFiscalConfirmacao>

      <Box sx={{ position: 'relative' }} paddingX={1}>
        <TitlePage
          iconChildren={<LocalShippingIcon size={48} />}
          title={'Entregas (Em Dev...)'}
        />

        <form onSubmit={handleClear}>
          <Grid container columnGap={2}>
            <Grid container md={6}   rowSpacing={1}>
              <Grid container md={12} columnSpacing={2} rowSpacing={1}  id="campoFiltro">
              <Grid item sm={3}>
                <TextField
                  
                  label="Nota Fiscal"
                  variant="filled"
                  size="small"
                  type="number"
                  fullWidth
                  sx={{ bgcolor: '#fff', borderRadius: 2 }}
                  onChange={(e) =>
                    debounce(() => {
                      setFiltro({
                        ...filtro,
                        documento: e.target.value,
                        emissaoInicio: null,
                      });
                    })
                  }
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                 
                  label="Nome"
                  size="small"
                  variant="filled"
                  fullWidth
                  sx={{ bgcolor: '#fff', borderRadius: 2 }}
                  onChange={(e) =>
                    debounce(() => {
                      setFiltro({
                        ...filtro,
                        nome: e.target.value,
                        emissaoInicio: null,
                      });
                    })
                  }
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  id="Romaneio"
                  label="Romaneio"
                  size="small"
                  variant="filled"
                  fullWidth
                  sx={{ bgcolor: '#fff', borderRadius: 2 }}
                  onChange={(e) =>
                    debounce(() => {
                      setFiltro({
                        ...filtro,
                        romaneio: e.target.value,
                        emissaoInicio: null,
                      });
                    })
                  }
                />
              </Grid>
              <Grid item sm={3} id="classificacao">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  sx={{ bgcolor: '#fff', borderRadius: 2 }}
                  options={classificFreteLista}
                  renderInput={(params) => (
                    <TextField {...params} label="Classificação Frete" />
                  )}
                  onChange={(event, newValue) => {
                    setFiltro({
                      ...filtro,
                      classificFrete: newValue?.value,
                    });
                  }}
                />
              </Grid></Grid>
              <Grid item >
                <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft:2 }} id='camposData'  >
                  <RadioGroup
                    
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="date"
                      control={
                        <Radio
                          checked={dateType === 'date'}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 20,
                            },
                          }}
                        />
                      }
                      label="Dia"
                      onClick={(e) => setDateType(e.target.value)}
                    />
                    <FormControlLabel
                      value="month"
                      control={
                        <Radio
                          checked={dateType === 'month'}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 20,
                            },
                          }}
                        />
                      }
                      label="Mês"
                      onClick={(e) => setDateType(e.target.value)}
                    />
                  </RadioGroup>
                  <Divider
                    orientation="vertical"
                    variant="fullWidth"
                    flexItem
                    sx={{
                      width: 2,
                      color: '#000',
                      bgcolor: '#000',
                      marginX: 2,
                    }}
                  />
                  <RadioGroup
                   
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="emissao"
                      control={
                        <Radio
                          checked={fluxoEntregaType.type === 'emissao'}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 20,
                            },
                          }}
                        />
                      }
                      label="Emissão"
                      onClick={(e) => setFluxoEntregaDateType(e.target.value)}
                    />
                    <FormControlLabel
                      value="previsao"
                      control={
                        <Radio
                          checked={fluxoEntregaType.type === 'previsao'}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 20,
                            },
                          }}
                        />
                      }
                      label="Previsão"
                      onClick={(e) => setFluxoEntregaDateType(e.target.value)}
                    />
                    <FormControlLabel
                      value="saida"
                      control={
                        <Radio
                          checked={fluxoEntregaType.type === 'saida'}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 20,
                            },
                          }}
                        />
                      }
                      label="Saída"
                      onClick={(e) => setFluxoEntregaDateType(e.target.value)}
                    />
                  </RadioGroup>
                </Box>
              </Grid>
              <Grid item md={12} container spacing={2} id='campoEmissao'>
              <Grid item sm={6} >
                <TextField
           
                  label={'Inicio de ' + fluxoEntregaType.label}
                  variant="filled"
                  size="small"
                  type={dateType}
                  focused
                  fullWidth
                  sx={{ bgcolor: '#fff', borderRadius: 2 }}
                  onChange={(e) =>
                    debounce(() => {
                      if (fluxoEntregaType.type === 'emissao')
                        setFiltro({
                          ...filtro,
                          emissaoInicio: e.target.value,
                        });
                      if (fluxoEntregaType.type === 'previsao')
                        setFiltro({
                          ...filtro,
                          previsaoInicio: e.target.value,
                        });
                      if (fluxoEntregaType.type === 'saida')
                        setFiltro({
                          ...filtro,
                          saidaInicio: e.target.value,
                        });
                    })
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  id="emissaoFim"
                  label={'Fim de ' + fluxoEntregaType.label}
                  variant="filled"
                  type={dateType}
                  fullWidth
                  size="small"
                  focused
                  sx={{ bgcolor: '#fff', borderRadius: 2 }}
                  onChange={(e) =>
                    debounce(() => {
                      if (fluxoEntregaType.type === 'emissao')
                        setFiltro({
                          ...filtro,
                          emissaoFim: e.target.value,
                        });
                      if (fluxoEntregaType.type === 'previsao')
                        setFiltro({
                          ...filtro,
                          previsaoFim: e.target.value,
                        });
                      if (fluxoEntregaType.type === 'saida')
                        setFiltro({
                          ...filtro,
                          saidaFim: e.target.value,
                        });
                    })
                  }
                />
              </Grid></Grid>
              <Grid item id='campoBotoes'>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button
                    variant="contained"
                    id="clear"
                    color="warning"
                    startIcon={<DeleteIcon />}
                    type="submit"
                  >
                    Clear
                  </Button>
                  <Button
                    id="leitorBarra"
                    variant="contained"
                    color="info"
                    startIcon={<ManageSearchIcon />}
                    type="button"
                    onClick={() => setShow(true)}
                  >
                    Leitor de Barra
                  </Button>
                  <div id="excel">
                    <ExcelEntregasButton
                      dataApi={entregaLista.excelAndCard}
                    ></ExcelEntregasButton>
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Grid id="infoEntregas" container md={6} spacing={2}  >
              <Grid item xs={6} md={3}>
                <NumberBox
                  title="Entregues"
                  backcolor="#008000"
                  amount={Number(entregaLista.excelAndCard.entregue)}
                  icon="entregas"
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <NumberBox
                  title="Media/dias"
                  backcolor="#0000FF"
                  amount={Number(entregaLista.excelAndCard.mediaDiaria)}
                  icon="entregas"
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <NumberBox
                  title="A sair"
                  backcolor="#800000"
                  amount={Number(entregaLista.excelAndCard.aSair)}
                  icon="entregas"
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <NumberBox
                  title="A entregar"
                  backcolor="#DC143C"
                  amount={Number(entregaLista.excelAndCard.aEntregar)}
                  icon="entregas"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            marginTop: 1,
            flexDirection: 'column-reverse',
            '@media (min-width: 1440px)': { flexDirection: 'row' },
          }}
        >
          <Box sx={{ '@meini(min-width: 1440px)': { width: '80%' } }}id="tabela">
            <div >
              <TabelaDeEntregas
              
                data={entregaLista.table}
                rowCount={entregaLista.table.length}
                loading={loading}
                handleChangeExpedicao={handleChangeExpedicao}
                handleNewRender={handleNewRender}
                restartRenderizacao={restartRenderizacao}
                novaRenderizacao={novaRenderizacao}
                
              />
            </div>
          </Box>
          <Box
            id="infoProducao"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
           className={styleAnime}
          >
            <Typography variant="h5" color="error">
              Nota Fiscal: {renderOverBreadCrumb?.notaFiscal}
            </Typography>
            <BreadCrump
              verticalStack={true}
              receiveNewRender={renderOverBreadCrumb}
              name1={'Produção'}
              idImage1={1}
              date1={formatDateTotvs(
                renderOverBreadCrumb.dataProducaoNewRender,
                '/'
              )}
              name2={'Carregamento'}
              idImage2={2}
              date2={formatDateTotvs(
                renderOverBreadCrumb.dataCarregamentoNewRender,
                '/'
              )}
              name3={'Previsão'}
              idImage3={3}
              date3={formatDateTotvs(
                renderOverBreadCrumb.dataTransporteNewRender,
                '/'
              )}
              name4={'Entregue'}
              idImage4={4}
              date4={formatDateTotvs(
                renderOverBreadCrumb.dataEntregueNewRender,
                '/'
              )}
              toMobile
            />
          </Box>
        </Box>
      </Box>
      <GuiaUtilizacao guiaUtil={guiaUtil} guiaUtilConfig={guiaUtilConfig} />
    </LayoutNovo>
  );
}
