import html2canvas from "html2canvas"
import jsPdf from "jspdf"

export const gerarPdfNfe = (html, nome) => {
    exportAsPdf(`${nome} ${new Date().toLocaleDateString()}`, html);
}

const exportAsPdf = (pdfName, html) => {
    html2canvas(html, { logging: true, letterRendering: 1, useCORS: true, scale: 1.5, })
        .then((canvas) => {
            const imgWidth = 200;

            const imgHeight = canvas.height * imgWidth / canvas.width;

            const imgData = canvas.toDataURL('img/png', 1.0);

            const pdf = new jsPdf('p', 'mm', 'a4');
            pdf.addImage(imgData, 'PNG', 5, 0, imgWidth, imgHeight);

            pdf.save(pdfName)
        });
};