import * as React from 'react';
import {
  FormControl,
  Box,
  Button,
  Typography,
  Modal,
  FormLabel,
  TextField,
} from '@mui/material';

import AddBoxIcon from '@mui/icons-material/AddBox';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';

import { atualizarPlanejamentoProducao } from '../../../../services/planejamentoSemanalProducao/planejamento.service';
import { consultaPlanejamento } from '../../../../services/planejamentoSemanalProducao/planejamento.service';
import { useToast } from '../../../../hooks/components/toast.hook';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function RegistrarQuantidadeProduzida({ value, handleUpdate }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { addToast } = useToast();

  const [data, setData] = React.useState(value);

  const handleSubmit = (value) => {
    atualizarPlanejamentoProducao(value)
      .then((response) => {
        handleUpdate(response);
        addToast({
          type: 'success',
          title: 'Registro efetuado.',
          description: 'Quantidade produzida foi registrado com sucesso.',
        });
      })
      .catch((error) => {
        addToast({
          type: 'danger',
          title: 'Registro não efetuado.',
          description:
            'O planejamento da semana não foi registrado com sucesso.',
        });
      });
    handleClose();
  };

  React.useEffect(() => {
    handleUpdate();
  }, []);

  return (
    <div>
      <Button onClick={handleOpen}>
        <AddBoxIcon sx={{ color: 'green' }} />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Registrar Quantidade Produzida
          </Typography>
          <FormControl sx={{ width: '300px', padding: '20px' }}>
            <FormLabel sx={{ marginBottom: '5px' }}>
              Quantidade Produzida
            </FormLabel>
            <TextField
              type="number"
              placeholder={'Quantidade Produzida'}
              variant="outlined"
              id="outlined-multiline-static"
              rows={1}
              onChange={(e) => {
                setData({ ...data, quantidadeProduzida: e.target.value });
              }}
              value={data.quantidadeProduzida}
            />
          </FormControl>
          <Box sx={{ display: 'flex', gap: 2, paddingY: 1 }} fullWidth>
            <Button
              variant="outlined"
              color="error"
              endIcon={<DeleteIcon />}
              fullWidth
              type="reset"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="success"
              endIcon={<SendIcon />}
              fullWidth
              type="submit"
              onClick={() => handleSubmit(data)}
            >
              Enviar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
