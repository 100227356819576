import { FormLabel } from 'react-bootstrap';
import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Modal,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import useUsuarioLocal from '../../../hooks/usuarioLocal.hook';
import { PostChamadosDetalhe } from '../../../services/chamadosDetalhe/chamadosDetalheX.service';
import ButtonCloseModal from "../../../components/ButtonCloseModal";

const initialNovoDetalhe = {
  idChamado: 0,
  descricao: null,
  previsaoEntrega: new Date().toJSON(),
  previsaoInicio: new Date().toJSON(),
  responsavelAprovacao: 'chamados@amvox.com.br',
  responsavelExecucao: null,
  observacao: '',
}
const style = {
  position: 'absolute',
  display: 'flex',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '70%',
  height: '50%',
  backgroundColor: '#f0f0f0',
  border: '2px solid #000',
  p: 4,
  display: 'flex',
  gap: 1,
  flexDirection: 'column',
  zIndex: 9999
};

export default function ModalAdicaoDetalhes({ openModal, handleModal, idChamadoSelecionado }) {

  const [formDataDet, setFormDataDet] = useState(initialNovoDetalhe);
  const { email } = useUsuarioLocal();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDataDet(() => ({
      ...formDataDet,
      [name]: value,
      idChamado: idChamadoSelecionado,
      responsavelExecucao: email,
    }));
  };

  const handleSubmitFormData = (e) => {
    PostChamadosDetalhe(formDataDet);
    handleModal();
  }
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>

      <Modal
        open={openModal}
        onClose={handleModal}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(1, 0, 0, 0.1)', // Deixa o backdrop mais claro
          },
        }}
        sx={style}
      >
        <Box>
          <ButtonCloseModal onClick={handleModal} />
          <Typography variant="h6" gutterBottom sx={{
            p: [2], gap: 1,
            textShadow: '1px #000000'
          }} >Adicionar comentário</Typography>

          <FormControl fullWidth>
            <TextField
              variant="filled"
              id="outlined-multiline-static"
              label="Descrição"
              name="descricao"
              placeholder="Descreva com todos os detalhes possíveis e claros"
              multiline
              rows={3}
              required
              value={formDataDet.descricao}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <TextField
              variant="filled"
              id="outlined-multiline-static"
              label="Observação"
              name="observacao"
              placeholder="Descreva com todos os detalhes possíveis e claros"
              multiline
              inputProps={{ maxLength: 99 }}
              rows={2}
              value={formDataDet.observacao}
              onChange={handleChange}
            />
          </FormControl>
          <Typography
            variant="body2"
            sx={{
              textAlign: 'center',
              marginBottom: 2,
              color: formDataDet.descricao?.length < 20 ? '#f00' : 'transparent',
            }}
          >
            {formDataDet.descricao?.length < 20
              ? `Faltam ${20 - formDataDet.descricao.length} caracteres para o mínimo.`
              : ''}
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, paddingY: 2 }} fullWidth>
            <Button
              variant="contained"
              color="success"
              endIcon={<AddIcon />}
              fullWidth
              type="submit"
              disabled={
                !formDataDet.responsavelAprovacao ||
                !formDataDet.responsavelExecucao ||
                !formDataDet.descricao ||
                formDataDet.descricao.length < 20
              }
              onClick={(e) => {
                handleSubmitFormData(e);
              }}
            >
              Adicionar
            </Button>

            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              fullWidth
              onClick={handleModal}
              type="reset"
            >
              Cancelar
            </Button>
          </Box>
        </Box>

      </Modal>
    </div>
  );
}