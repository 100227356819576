import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { IoMdArrowDropdownCircle } from 'react-icons/io';
import ButtonCloseModal from '../../../../components/ButtonCloseModal';
import Select from 'react-select';
import { useState, useEffect } from 'react';
import { useToast } from '../../../../hooks/components/toast.hook';
import {
  calcularPlanejamentoProducao,
  registrarPlanejamentoProducao,
  consultaPlanejamento,
} from '../../../../services/planejamentoSemanalProducao/planejamento.service';
import moment from 'moment/moment';

const style = {
  margin: 'auto',
  backgroundColor: 'background.paper',
  width: '90%',
  height: '95%',
  marginTop: '15px',
  padding: '35px',
  position: 'relative',
  display: 'flex',
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit',
  height: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding: 4,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  overflowInline: 'auto',
};

export default function ModalRegistro({
  openModal,
  handleClose,
  produtosLista,
}) {
  const [listaPlanejamento, setListaPlanejamento] = useState([]);
  const [planejamentoSemanal, setPlanejamentoSemanal] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [abrirPlanejamento, setAbrirPlanejamento] = useState(false);
  const [calculoPlanejamento, setCalculoPlanejamento] = useState(false);
  const [produtoFinal, setProdutoFinal] = useState([]);
  const [dataDaSemana, setDatasDaSemana] = useState('');
  const [isFilled, setIsFilled] = useState({
    quantidadeProdutos: false,
    produtoSelecionado: false,
    linhasDisponiveis: false,
    diasDisponiveis: false,
    horasDisponiveis: false,
  });
  const [isCalculoFilled, setIsCalculoFilled] = useState({
    semanaAno: false,
    observação: false,
    horasDisponiveisDias: false,
  });

  const { addToast } = useToast();

  const handleCloseModal = () => setOpen(false);
  const handleOpenModal = () => setOpen(true);

  const selectProdutosLista = produtosLista.map((item) => ({
    value: item.codigo,
    label: item.apelido,
  }));

  const handleAddPlanejamento = (value) => {
    if (
      isFilled.diasDisponiveis == false ||
      isFilled.horasDisponiveis == false ||
      isFilled.quantidadeProdutos == false ||
      isFilled.produtoSelecionado == false
    ) {
      addToast({
        type: 'danger',
        title: 'Preencha os campos corretamente',
        description: 'Os campos não podem ser enviados dessa maneira',
      });
    } else {
      setAbrirPlanejamento(true);
      setCalculoPlanejamento(false);
      setListaPlanejamento((old) => [...old, value]);
      setData({
        quantidadeProdutos: '',
        produtoSelecionado: {},
      });
      setIsFilled({
        quantidadeProdutos: false,
        produtoSelecionado: false,
        diasDisponiveis: true,
        horasDisponiveis: true,
        linhasDisponiveis: true,
      });
    }
  };

  const handleDeleteNota = () => {
    setListaPlanejamento((prev) => {
      const novaLista = [...prev];
      novaLista.pop();
      if (!novaLista.length == true) {
        setCalculoPlanejamento(false);
        setAbrirPlanejamento(false);
        data.diasDisponiveis = '';
        data.linhasDisponiveis = '';
        data.horasDisponiveis = '';
        data.quantidadeOperadores = '';
      } else {
        setAbrirPlanejamento(true);
        setCalculoPlanejamento(false);
      }
      setIsFilled({
        diasDisponiveis: false,
        horasDisponiveis: false,
        linhasDisponiveis: false,
      });
      return novaLista;
    });
  };

  const handlePostPlanejamento = async (value) => {
    const codigo = value.map((item) => item.produtoSelecionado.value);
    const quantidade = value.map((item) => item.quantidadeProdutos);
    const diasDisponiveis = value[0]?.diasDisponiveis;
    const horasDisponiveis = value[0]?.horasDisponiveis;
    const linhasDisponiveis = value[0]?.linhasDisponiveis;
    const quantidadeOperadores = value[0]?.quantidadeOperadores;
    let result = [{}];
    codigo.forEach((item, index) => {
      result.push({ codigo: `${item}`, quantidade: `${quantidade[index]}` });
    });
    result.shift();
    const resultado = {
      diasDisponiveis: diasDisponiveis,
      diasHorasDisponiveis: horasDisponiveis,
      linhasDisponiveis: linhasDisponiveis,
      quantidadeOperadores: quantidadeOperadores,
      produtos: result,
    };
    calcularPlanejamentoProducao(resultado)
      .then((response) => {
        setPlanejamentoSemanal(response.result);
        setCalculoPlanejamento(true);
        addToast({
          type: 'success',
          title: 'Cálculo feito corretamente.',
          description: 'Prossiga o processo com o botão abaixo',
        });
      })
      .catch((error) => {
        addToast({
          type: 'danger',
          title: 'Meta Hora do produto não encontrada.',
          description:
            'Reveja o produto ou entre em contato com o setor de inteligência',
        });
      });
  };

  function formatarNumero(numero) {
    return numero.toFixed(2);
  }

  const handleGetDiasDaSemana = (value) => {
    const semanaEscolhida = moment(value);

    const comecoSemana = semanaEscolhida.startOf('isoWeek');

    const diasDaSemana = [];

    for (let i = 0; i < 5; i++) {
      const dia = comecoSemana.clone().add(i, 'days');
      diasDaSemana.push({
        data: dia.format(),
      });
    }

    setDatasDaSemana(diasDaSemana);
  };

  const handlePostProdutoFinal = async (value) => {
    const quantidade = listaPlanejamento.map((item) => item.quantidadeProdutos);
    const agrupamentoCodigo = value.produtos;
    const codigo = agrupamentoCodigo.map((item) => item.codigo);

    let result = [{}];
    codigo.forEach((item, index) => {
      result.push({ codigo: `${item}`, quantidade: `${quantidade[index]}` });
    });
    result.shift();

    setProdutoFinal({
      semanaAno: produtoFinal.semanaAno.substring(6, 8),
      periodoInicio: dataDaSemana[0]?.data,
      periodoFim: dataDaSemana[dataDaSemana.length - 1]?.data,
      horasNecessarias: value.horasNecessarias,
      horasDisponiveis: value.horasDisponiveis,
      capacidadeUtilizada: value.capacidadeUtilizada,
      horasDisponiveisDias: produtoFinal.horasDisponiveisDias,
      linhasDisponiveis: value.linhasDisponiveis,
      quantidadeOperadores: value.quantidadeOperadores,
      diasDisponiveis: value.diasDisponiveis,
      observação: produtoFinal.observacao,
      produtos: result,
    });
  };

  useEffect(() => {
    registrarPlanejamentoProducao(produtoFinal)
      .then((response) => {
        if (response.value.message === 'Planejamento Registrado') {
          addToast({
            type: 'success',
            title: 'Registro efetuado.',
            description: 'O planejamento da semana foi registrado com sucesso.',
          });
          handleCloseModal(true);
          handleClose(true);
        } else {
          addToast({
            type: 'danger',
            title: 'Ocorreu algum erro ao fazer o planejamento.',
            description: 'Entre em contato com o setor de inteligência.',
          });
        }
      })
      .catch((error) => {
        console.error('Erro', error);
      });
  }, [produtoFinal]);

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <ButtonCloseModal
            sx={{ position: 'absolute', top: 0 }}
            onClick={handleClose}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              border: 1,
              borderRadius: 3,
              padding: '15px',
              height: '100%',
              width: '60%',
            }}
          >
            <Typography
              variant="h6"
              component={'p'}
              textAlign={'center'}
              color={'#000'}
            >
              Planejamento Semanal
            </Typography>
            <FormControl sx={{ width: '20rem', padding: '20px' }}>
              <FormLabel sx={{ marginBottom: '5px' }}>
                Selecione o produto para planejar
              </FormLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                placeholder={'Produtos'}
                options={selectProdutosLista}
                onChange={(e) => {
                  setData({ ...data, produtoSelecionado: e });
                  setIsFilled({ ...isFilled, produtoSelecionado: true });
                }}
                required
                value={data.produtoSelecionado}
              />
            </FormControl>
            <FormControl sx={{ width: '12rem', padding: '20px' }}>
              <FormLabel sx={{ marginBottom: '5px' }}>
                Dias disponíveis
              </FormLabel>
              <TextField
                type="number"
                placeholder={'5'}
                variant="outlined"
                id="outlined-multiline-static"
                name="patrimonio"
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, diasDisponiveis: e.target.value });
                  setIsFilled({ ...isFilled, diasDisponiveis: true });
                }}
                value={data.diasDisponiveis}
              />
            </FormControl>
            <FormControl sx={{ width: '12rem', padding: '20px' }}>
              <FormLabel sx={{ marginBottom: '5px' }}>
                Horas disponíveis
              </FormLabel>
              <TextField
                type="number"
                placeholder={'8'}
                variant="outlined"
                id="outlined-multiline-static"
                name="patrimonio"
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, horasDisponiveis: e.target.value });
                  setIsFilled({
                    ...isFilled,
                    horasDisponiveis: true,
                  });
                }}
                value={data.horasDisponiveis}
              />
            </FormControl>
            <FormControl sx={{ width: '12rem', padding: '20px' }}>
              <FormLabel sx={{ marginBottom: '5px' }}>
                Linhas disponíveis
              </FormLabel>
              <TextField
                type="number"
                variant="outlined"
                id="outlined-multiline-static"
                placeholder={'7'}
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, linhasDisponiveis: e.target.value });
                  setIsFilled({
                    ...isFilled,
                    linhasDisponiveis: true,
                  });
                }}
                value={data.linhasDisponiveis}
              />
            </FormControl>
            <FormControl sx={{ width: '12rem', padding: '20px' }}>
              <FormLabel sx={{ marginBottom: '5px' }}>
                Quantidade Operadores
              </FormLabel>
              <TextField
                type="number"
                variant="outlined"
                id="outlined-multiline-static"
                placeholder={'12'}
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, quantidadeOperadores: e.target.value });
                  setIsFilled({
                    ...isFilled,
                    quantidadeOperadores: true,
                  });
                }}
                value={data.quantidadeOperadores}
              />
            </FormControl>
            <FormControl sx={{ width: '300px', padding: '20px' }}>
              <FormLabel sx={{ marginBottom: '5px' }}>
                Quantidade de produtos a ser feita
              </FormLabel>
              <TextField
                type="number"
                placeholder={'Quantidade a ser produzida'}
                variant="outlined"
                id="outlined-multiline-static"
                name="patrimonio"
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, quantidadeProdutos: e.target.value });
                  setIsFilled({
                    ...isFilled,
                    quantidadeProdutos: true,
                  });
                }}
                value={data.quantidadeProdutos}
              />
            </FormControl>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={() => handleDeleteNota()}
                type="submit"
                size="large"
                color="error"
                variant="contained"
              >
                Deletar planejamento
              </Button>

              <Button
                onClick={() => handleAddPlanejamento(data)}
                type="submit"
                size="large"
                color="primary"
                variant="contained"
              >
                Adicionar mais produtos
              </Button>

              <Button
                onClick={() => handlePostPlanejamento(listaPlanejamento)}
                type="submit"
                size="large"
                color="success"
                variant="contained"
              >
                Confirmar Planejamento
              </Button>
            </div>

            <Modal
              style={{ overflow: 'auto', overflowInline: 'auto' }}
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              {calculoPlanejamento ? (
                <Box sx={style2}>
                  <h1 style={{ textAlign: 'center', marginBottom: '15px' }}>
                    Cálculo de planejamento
                  </h1>
                  <section
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: '5px',
                    }}
                  >
                    <div>
                      <FormLabel sx={{ marginBottom: '5px' }}>
                        Horas necessárias
                      </FormLabel>
                      <p style={{ textAlign: 'center' }}>
                        {formatarNumero(planejamentoSemanal.horasNecessarias)}
                      </p>
                    </div>

                    <div>
                      <FormLabel sx={{ marginBottom: '5px' }}>
                        Capacidade utilizada
                      </FormLabel>
                      <p style={{ textAlign: 'center' }}>
                        {formatarNumero(
                          planejamentoSemanal.capacidadeUtilizada
                        )}
                      </p>
                    </div>

                    <div>
                      <FormLabel sx={{ marginBottom: '5px' }}>
                        Horas disponíveis
                      </FormLabel>
                      <p style={{ textAlign: 'center' }}>
                        {planejamentoSemanal.horasDisponiveis}
                      </p>
                    </div>
                  </section>

                  {planejamentoSemanal.produtos.map((item) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Accordion>
                        <AccordionSummary
                          sx={{
                            width: 'fit',
                            padding: '10px',
                            position: 'relative',
                            border: '1px solid #000000',
                          }}
                          expandIcon={
                            <IoMdArrowDropdownCircle
                              style={{ color: 'black' }}
                              size={30}
                            />
                          }
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Typography>Planejamento {item.codigo}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            backgroundColor: '#AFAFAF',
                            border: '1px solid #000000',
                          }}
                        >
                          <p>Nome: {item.nome}</p>
                          <p>Código: {item.codigo}</p>
                          <p>
                            Horas necessárias:{' '}
                            {formatarNumero(item.horasNecessarias)}
                          </p>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '20px',
                      gap: '15px',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <FormLabel
                        sx={{
                          marginBottom: '10px',
                          marginTop: '25px',
                          marginLeft: '10px',
                        }}
                      >
                        Semana do ano
                      </FormLabel>
                      <TextField
                        type="week"
                        id="outlined-basic"
                        color="success"
                        variant="outlined"
                        required
                        onChange={(e) => {
                          handleGetDiasDaSemana(e.target.value);
                          setProdutoFinal({
                            ...produtoFinal,
                            semanaAno: e.target.value,
                          });
                          setIsCalculoFilled({
                            ...isCalculoFilled,
                            horasDisponiveisDias: true,
                          });
                        }}
                      />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <FormLabel
                        sx={{
                          marginBottom: '10px',
                          marginTop: '25px',
                          marginLeft: '10px',
                        }}
                      >
                        Horas disponíveis/dia
                      </FormLabel>
                      <TextField
                        type="number"
                        id="outlined-basic"
                        color="success"
                        label="Horas disponíveis/dia"
                        variant="outlined"
                        required
                        onChange={(e) => {
                          setProdutoFinal({
                            ...produtoFinal,
                            horasDisponiveisDias: e.target.value,
                          });
                          setIsCalculoFilled({
                            ...isCalculoFilled,
                            horasDisponiveisDias: true,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormLabel
                      sx={{
                        marginBottom: '10px',
                        marginTop: '25px',
                        marginLeft: '10px',
                      }}
                    >
                      Observação
                    </FormLabel>
                    <textarea
                      style={{ padding: '8px' }}
                      onChange={(e) => {
                        setProdutoFinal({
                          ...produtoFinal,
                          observacao: e.target.value,
                        });
                        setIsCalculoFilled({
                          ...isCalculoFilled,
                          observação: true,
                        });
                      }}
                      placeholder="Observação"
                      rows="5"
                      cols="33"
                    ></textarea>
                  </div>
                  <Button
                    sx={{ margin: '0 auto', marginTop: '25px' }}
                    type="submit"
                    variant="contained"
                    color="success"
                    onClick={() => handlePostProdutoFinal(planejamentoSemanal)}
                  >
                    Enviar Planejamento
                  </Button>
                </Box>
              ) : (
                <span></span>
              )}
            </Modal>
          </Box>
          {abrirPlanejamento ? (
            <section
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '40%',
                overflow: 'auto',
                overflowInline: 'auto',
              }}
            >
              <div
                style={{
                  borderRadius: '20px',
                  margin: '10px',
                  padding: '8px',
                }}
              >
                <section>
                  <h1>Planejamento</h1>
                  <div
                    style={{
                      backgroundColor: '#AFAFAF',
                      border: '1px solid #000000',
                    }}
                  >
                    <div style={{ padding: '8px' }}>
                      <p>
                        Linhas disponíves{' '}
                        {listaPlanejamento[0]?.linhasDisponiveis}
                      </p>
                      <p>
                        Dias disponíveis {listaPlanejamento[0]?.diasDisponiveis}
                      </p>
                      <p>
                        Horas disponíveis{' '}
                        {listaPlanejamento[0]?.horasDisponiveis}
                      </p>
                    </div>
                    {listaPlanejamento.map((item) => (
                      <div
                        style={{
                          border: '1px solid black',
                          padding: '12px',
                        }}
                      >
                        <ul>
                          <li>Produto {item.produtoSelecionado.label}</li>
                          <li>
                            Código do Produto {item.produtoSelecionado.value}
                          </li>
                          <li>
                            Quantidade a ser produzida {item.quantidadeProdutos}
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                </section>
              </div>
              {calculoPlanejamento ? (
                <Button
                  style={{ width: '70%', margin: '0 auto' }}
                  onClick={handleOpenModal}
                  type="submit"
                  size="large"
                  color="success"
                  variant="contained"
                >
                  Abrir cálculo
                </Button>
              ) : (
                <span></span>
              )}
            </section>
          ) : (
            <div></div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
